import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useAuth } from "./hooks/AuthProvider";
import CheckoutForm from "./CheckoutForm";
import "./App.css";
// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.

console.log("REACT_APP_STRIPE_KEY", process.env.REACT_APP_STRIPE_KEY);
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

export default function App() {
  const [clientSecret, setClientSecret] = useState("");
  const user = useAuth();
  console.log("user", user);
  useEffect(() => {
    console.log("REACT_APP_API_URL", process.env.REACT_APP_API_URL);
    if (user?.token) {
      fetch(`${process.env.REACT_APP_API_URL}/api/stripe/payment-intent/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => setClientSecret(data.client_secret));
    }
  }, [user]);

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div className="App">
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      )}
    </div>
  );
}
