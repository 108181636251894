import { useContext, createContext, useState, useEffect } from "react";
const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem("site") || "");

  useEffect(() => {
    const access = new URLSearchParams(window.location.search).get("access");
    if (access) {
      localStorage.setItem("site", access);
      setToken(access);
    }
  }, [token]);
  console.log("token ", token);
  return (
    <AuthContext.Provider value={{ token }}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};
